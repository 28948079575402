import React from 'react';
import Title from './component/Title';
import ServicesFeature from './component/ServicesFeature';
import Tesimonials from './component/Testimonials';
import Contact from '../Home/component/Contact';
import Cta from '../Home/component/Cta';

const index = () => {
    return (
        <>
            <Title />
            <ServicesFeature />
            <Tesimonials />
            <Cta />
            <Contact />
        </>
    );
};

export default index;
