const serviveData = [
    {
        id: 1,
        iconClassName: 'icon-24',
        title: 'Lettings',
        desc: 'Property lettings refer to the process of renting out a property to tenants.',
        detailslink: '/lettings-details',
    },
    {
        id: 2,
        iconClassName: 'icon-25',
        title: 'Rentings',
        desc: 'Renting a property refers to the process of paying a fee to occupy and use a property owned by someone else',
        detailslink: '/renting-details',
    },
    {
        id: 3,
        iconClassName: 'icon-26',
        title: 'Landlords',
        desc: 'Landlord services refer to the various tasks and responsibilities that landlords or property managers carry out to effectively manage a rental property.',
        detailslink: '/landlords-details',
    },
    {
        id: 4,
        iconClassName: 'icon-27',
        title: 'Tenants',
        desc: 'A tenant is someone who rents and occupies a property owned by a landlord or property owner.',
        detailslink: '/tenants-details',
    },
    {
        id: 5,
        iconClassName: 'icon-28',
        title: 'Property Refurbishments',
        desc: 'Property refurbishment refers to the process of renovating, repairing, or updating a property to improve its overall condition, functionality, and value.',
        detailslink: '/refurbishments-details',
    },
    {
        id: 6,
        iconClassName: 'icon-29',
        title: 'Property Management',
        desc: 'Property management refers to the professional management of real estate properties on behalf of property owners or landlords.',
        detailslink: '/management-details',
    },
];

export default serviveData;
