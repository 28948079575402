import React from 'react';

const Error = () => {
    return (
        <>
            {/* error-section */}
            <section className="error-section centred">
                <div className="auto-container">
                    <div className="inner-box">
                        <h1>404</h1>
                        <h2>
                            page is not found. <br />
                            the page is doesn’t exist or deleted
                        </h2>
                        <a href="/" className="theme-btn btn-one">
                            Go To Home
                        </a>
                    </div>
                </div>
            </section>
            {/* error-section end */}
        </>
    );
};

export default Error;
