import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// ** Third Party Components
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';
const MessageRight = () => {
    const form = useRef();

    const validationScheme = yup.object().shape({
        username: yup.string().required('Your Name is required.'),
        email: yup.string().email().required('Email is required.'),
        phone: yup.number().required('Contact No is required.').typeError('Please Enter Valid Number').nullable(),
        // subject: yup.string().required('Subject  is required.'),
        message: yup.string().required('Message  is required.'),
    });

    // ** Hook Form
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme),
    });
    // register form fields
    const { ref: refUserName, ...restUserName } = register('username');
    const { ref: refEmail, ...restEmail } = register('email');
    const { ref: refPhone, ...restPhone } = register('phone');
    // const { ref: refSubject, ...restSubject } = register('subject');
    const { ref: refMessage, ...restMessage } = register('message');

    const sendEmail = (e) => {
        // e.preventDefault();

        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then(
                (result) => {
                    toast.success('Messege Sent Successfully.');
                    reset();
                    // console.log(result.text);
                },
                (error) => {
                    toast.error(error.response?.data?.message || 'Something went wrong!');
                    // console.log(error.text);
                }
            );
    };

    return (
        <form ref={form} onSubmit={handleSubmit(sendEmail)} className="default-form">
            <div className="form-group">
                <input
                    type="text"
                    name="username"
                    placeholder="Your Name"
                    required=""
                    invalid={errors?.username && true}
                    {...restUserName}
                    ref={refUserName}
                />
                {errors.username && <span className="text-danger">{errors.username?.message}</span>}
            </div>
            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                    required=""
                    invalid={errors?.email && true}
                    {...restEmail}
                    ref={refEmail}
                />
                {errors.email && <span className="text-danger">{errors.email?.message}</span>}
            </div>
            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    required=""
                    maxLength="10"
                    invalid={errors?.phone && true}
                    {...restPhone}
                    ref={refPhone}
                />
                {errors.phone && <span className="text-danger">{errors.phone?.message}</span>}
            </div>
            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Message"
                    defaultValue={''}
                    invalid={errors?.message && true}
                    {...restMessage}
                    ref={refMessage}
                />
                {errors.message && <span className="text-danger">{errors.message?.message}</span>}
            </div>
            <div className="form-group message-btn">
                <button type="submit" className="theme-btn btn-one">
                    Send Message
                </button>
            </div>
        </form>
    );
};

export default MessageRight;
