const AboutContentData = [
    {
        id: 1,
        serviceImg: 'assets/images/resource/about-1.jpg',
        shortDesc: 'Services we offer at Imperial Lettings North East.',
        name: 'Manav Seth',
        longDesc:
            // ' is a sole Director of Imperial Lettings (North East) Limited. A professional letting agent, based in BIRTLEY (County Durham of UK). Imperial Lettings (North East) Limited has been providing housing management services to selected and premium private Investors in the NorthEast region for more than 10 years. Manav Seth is a postgraduate in Housing and Policy Management from Northumbria University and also, acquired a Chartered status with CIH. I am always approachable and a professional service is my top priority.  I work with a good team of contractors (Tradesman) to look after the maintenance side of properties and most importantly, I truly understand the needs of my clients with a clear business model. A leading position in the private rental sector required unrivalled expertise, innovative approaches and excellent customer service. Looking forward to working with valuable PRIVATE INVESTORS in the North East of UK.',
            ' is a sole Director of Imperial Lettings (North East) Limited. A professional Letting agent, based in Chester Le Street (County Durham of UK), who has been providing Housing Management Services to Premium Investors and Private Landlords in the North East region for more than 15 years. A postgraduate in Housing and Policy Management from Northumbria University, Manav Seth also acquired a Chartered status with Chartered Institute of Housing. I truly understand the needs of my clients with a good income growth to their portfolio and most importantly a professional Housing Management Service is top priority.  I work with a good team of professional registered contractors (Tradesman) to look after the repairs and maintenance side of properties. A leading position in the private rental sector required unrivalled expertise, innovative approaches and excellent customer service.',
        // serviceList: ['Lettings', 'Rentings', 'Landlords', 'Tenants', 'Property Refurbishment', '  Property Management'],
        btnName: 'Read More',
        btnLink: '/contact',
    },
    {
        id: 2,
        serviceImg: 'assets/images/resource/about-4.jpg',
        serviceTitle: 'Letting',
        serviceName: 'Dolor sit amet consectetur',
        shortDesc: 'dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat.',
        longDesc:
            ' At Imperial we are providing a professional and efficient service is our top priority. Our team is dedicated to providing prompt, friendly and professional services at all times because they have been trained to the highest standards. A leading position in the market requires unrivalled expertise, innovative approaches, and excellent customer service.',
        serviceList: ['consectetur elit sed do eius', 'consectetur elit sed'],
        btnName: 'Read More',
        btnLink: '/contact',
    },
];

export default AboutContentData;
