import React from 'react';

const Title = () => {
    return (
        <>
            {/*Page Title*/}
            <section
                className="page-title centred"
                style={{
                    backgroundImage: 'url(assets/images/background/page-title-3.jpg)',
                }}
            >
                <div className="auto-container">
                    <div className="content-box clearfix">
                        <h1>Our Services</h1>
                        <ul className="bread-crumb clearfix">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Our Services</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*End Page Title*/}
        </>
    );
};

export default Title;
