import React, { Fragment } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Card } from 'react-bootstrap';
import './Testimonial.css';
import TestimonialData from '../../Home/component/TestimonialData';
const Testimonial = () => {
    return (
        <>
            {/* testimonial-style-four */}
            <section className="testimonial-style-four sec-pad centred bg-color-1">
                <div className="auto-container">
                    <div className="sec-title">
                        <h5>Testimonials</h5>
                        <h2>What They Say About Us</h2>
                        {/* <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmod tempor incididunt <br />
                            labore dolore magna aliqua enim.
                        </p> */}
                    </div>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        slidesPerGroup={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        breakpoints={{
                            468: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 50,
                            },
                        }}
                        loop={true}
                        pagination={true}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="about-custom"
                    >
                        {TestimonialData.map((i) => {
                            return (
                                <Fragment key={i?.id}>
                                    <SwiperSlide>
                                        <Card className="border-0">
                                            <div className="testimonial-block-three">
                                                <div className="inner-box">
                                                    <div className="icon-box">
                                                        <i className={i?.iconClassName} key={i?.iconClassName} />
                                                    </div>
                                                    <h4 key={i?.desc}>{i?.desc}</h4>
                                                    <h5 key={i?.testimonialName}>{i?.testimonialName}</h5>
                                                    <span className="designation" key={i?.designation}>
                                                        {i?.designation}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card>
                                    </SwiperSlide>
                                </Fragment>
                            );
                        })}
                    </Swiper>
                </div>
            </section>
            {/* testimonial-style-four end */}
        </>
    );
};

export default Testimonial;
