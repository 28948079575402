import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// ** Css
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
// ** Pages import
import Header from './Pages/Navbar';
import Home from './Pages/Home';
import ContectUs from './Pages/ContectUs';
import About from './Pages/About';
import ServicesOne from './Pages/Services1';
// import ServicesTwo from './Pages/Services2';
import Gallery from './Pages/Gallery';
import Error from './Pages/Error';
import Footer from './Pages/Footer/index';
import LettingsDetails from './Pages/PropertyDetails/LettingsDetails';
import RentingsDetails from './Pages/PropertyDetails/RentingsDetails';
import LandlordsDetails from './Pages/PropertyDetails/LandlordsDetails';
import RefurbishmentsDetails from './Pages/PropertyDetails/RefurbishmentsDetails';
import ManagementDetails from './Pages/PropertyDetails/ManagementDetails';
import TenantsDetails from './Pages/PropertyDetails/TenantsDetails';
// ** Third party package
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import ScrollToTop from './scrollToTop';
// import Partner from './Pages/Footer/component/Partner';
const App = () => {
    return (
        <>
            <ToastContainer position="top-right" autoClose={1500} />
            <BrowserRouter>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route path="/contact" element={<ContectUs />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/services1" element={<ServicesOne />}></Route>
                    {/* <Route path="/services2" element={<ServicesTwo />}></Route> */}
                    <Route path="/gallery" element={<Gallery />}></Route>
                    <Route path="/lettings-details" element={<LettingsDetails />}></Route>
                    <Route path="/renting-details" element={<RentingsDetails />}></Route>
                    <Route path="/landlords-details" element={<LandlordsDetails />}></Route>
                    <Route path="/refurbishments-details" element={<RefurbishmentsDetails />}></Route>
                    <Route path="/management-details" element={<ManagementDetails />}></Route>
                    <Route path="/tenants-details" element={<TenantsDetails />}></Route>
                    <Route path="*" element={<Error />} />
                </Routes>

                <Footer />
            </BrowserRouter>
        </>
    );
};

export default App;
