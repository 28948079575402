import React, { Fragment } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
// import { Card } from "react-bootstrap";
import './Deal.css';
import DealData from './DealData';
const Deal = () => {
    //   console.log("DealData", DealData);
    return (
        <>
            <section className="deals-style-two sec-pad deal-custom ">
                <div className="auto-container">
                    <div className="sec-title centred">
                        <h5>Featured Property</h5>
                        <h2>Our Best Deals</h2>
                    </div>
                </div>

                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    // slidesPerGroup={4}
                    autoplay={false}
                    breakpoints={{
                        468: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                    }}
                    loop={true}
                    pagination={false}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="deal-custum"
                >
                    {DealData.map((i) => {
                        return (
                            <Fragment key={i?.id}>
                                <SwiperSlide>
                                    <div className="row auto-container">
                                        <div className="col-lg-6 col-md-6 col-sm-12 deals-block">
                                            <div className="deals-block-one">
                                                <div className="inner-box">
                                                    <div className="lower-content">
                                                        <div className="title-text">
                                                            <h4>
                                                                <a href="property-details.html">{i?.propertyTitle}</a>
                                                            </h4>
                                                        </div>
                                                        <div className="price-box clearfix">
                                                            <div className="price-info pull-left">
                                                                {/* <h6>Rent</h6> */}
                                                                <h4>{i?.propertyPrice}</h4>
                                                            </div>
                                                            {/* <div className="author-box pull-right">
                                  <figure className="author-thumb">
                                    <img src={i?.propertyAutherImg} alt="" />
                                    <span>{i?.propertyAutherName}</span>
                                  </figure>
                                </div> */}
                                                        </div>
                                                        <p className="property-height-fix">{i?.propertyDesc}</p>
                                                        <ul className="more-details clearfix">
                                                            <li>
                                                                <i className="icon-14" />
                                                                {i?.propertyMoreDetails[0]}
                                                            </li>
                                                            {/* <li>
                                  <i className="icon-15" />{" "}
                                  {i?.propertyMoreDetails[1]}
                                </li> */}
                                                            <li>
                                                                <i className="icon-16" />
                                                                {i?.propertyMoreDetails[1]}
                                                            </li>
                                                        </ul>
                                                        <div className="other-info-box clearfix">
                                                            <div className="btn-box pull-left">
                                                                <a href="/contact" className="theme-btn btn-one">
                                                                    See Details
                                                                </a>
                                                            </div>
                                                            {/* <ul className="other-option pull-right clearfix">
                                  <li>
                                    <a href="#">
                                      <i className="icon-12" />
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="icon-13" />
                                    </a>
                                  </li>
                                </ul> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 deals-block">
                                            <div className="image-box">
                                                <figure className="image">
                                                    <Swiper
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                        // slidesPerGroup={4}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        loop={true}
                                                        pagination={false}
                                                        navigation={false}
                                                        modules={[Autoplay, Pagination, Navigation]}
                                                        className="deal-inner-swiper"
                                                    >
                                                        {i.PropertyImages?.map((itemImg) => {
                                                            return (
                                                                <Fragment key={itemImg?.id}>
                                                                    <SwiperSlide>
                                                                        <img
                                                                            src={itemImg?.img}
                                                                            alt=""
                                                                            style={{
                                                                                height: '400px',
                                                                                width: '100%',
                                                                            }}
                                                                            className="deal-img"
                                                                        />
                                                                    </SwiperSlide>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Swiper>
                                                </figure>
                                                <div className="batch">
                                                    <i className="icon-11" />
                                                </div>
                                                <span className="category">Featured</span>
                                                <div className="buy-btn">
                                                    <a href="/renting-details">For Rent</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Fragment>
                        );
                    })}
                </Swiper>
            </section>
        </>
    );
};

export default Deal;
