import React, { useState } from 'react';
import {
    Container,
    // Dropdown,
    Nav,
    NavDropdown,
    Navbar,
    Offcanvas,
} from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './Header.css';
// ** Css
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import logo from '../../../logowhite.svg';

import useSticky from './useStricky';
import classNames from 'classnames';

// ** Icon import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const location = useLocation();

    const { sticky, stickyRef } = useSticky();

    // scroll button
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    window.addEventListener('scroll', toggleVisible);

    return (
        <div className="boxed_wrapper">
            <header className="main-header">
                <div className="header-top">
                    <div className="top-inner clearfix">
                        <div className="left-column pull-left">
                            <ul className="info clearfix">
                                <li>
                                    <i className="far fa-map-marker-alt"></i>
                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                </li>

                                <li>
                                    <i className="far fa-phone" />
                                    <a href="tel:07985 193841">07985 193841</a>
                                </li>
                            </ul>
                        </div>
                        <div className="right-column pull-right">
                            <ul className="social-links clearfix">
                                <li>
                                    <a href="https://www.facebook.com/imperiallettingsnortheast" target="_blank">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/manav-seth-15844111/?originalSubdomain=uk" target="_blank">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/imperial_lettings_northeast/" target="_blank">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="/">
                                        <i className="fab fa-google-plus-g" />
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>

                <div ref={stickyRef} className={classNames('nav_custom flex', { sticky })}>
                    <div className="outer-box">
                        <div className="main-box">
                            <div className="logo-box">
                                <div className="logo">
                                    <a href="/">
                                        <img src="assets/images/logosvg.svg" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="clearfix">
                                {['xl'].map((expand) => (
                                    <Navbar
                                        key={expand}
                                        sticky="top"
                                        expand={expand}
                                        show={expand}
                                        className="navbar-expand-lg navbar-light "
                                    >
                                        <Container fluid>
                                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                            <Navbar.Offcanvas
                                                id={`offcanvasNavbar-expand-${expand}`}
                                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                                placement="end"
                                                className="offcanvas-custom"
                                            >
                                                <Offcanvas.Header closeButton>
                                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                        <div className="nav-logo">
                                                            <a href="/">
                                                                <img src={logo} alt="logo" title="" width={200} height={200} />
                                                            </a>
                                                        </div>
                                                    </Offcanvas.Title>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body className="pt-0">
                                                    <Nav className="me-auto justify-content-end" activeKey={location.pathname}>
                                                        <span className="nav-border-custom"></span>
                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/lettings-details"
                                                                className={location.pathname === '/lettings-details' && 'active'}
                                                                // onClick={() => window.location.replace('/services2#letting')}
                                                            >
                                                                Lettings
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>
                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/renting-details"
                                                                className={location.pathname === '/renting-details' && 'active'}
                                                            >
                                                                Renting
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>
                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/tenants-details"
                                                                className={location.pathname === '/tenants-details' && 'active'}
                                                            >
                                                                Tenants
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>
                                                        <NavDropdown
                                                            // title="Our Services"
                                                            id="nav-dropdown-services"
                                                            renderMenuOnMount={true}
                                                            // className={location.pathname === '/services1' && 'active'}
                                                            title={
                                                                <span>
                                                                    <Link
                                                                        to="/services1"
                                                                        className={location.pathname === '/services1' && 'active'}
                                                                    >
                                                                        Services
                                                                        <FontAwesomeIcon
                                                                            icon={faChevronDown}
                                                                            style={{ marginLeft: '10px' }}
                                                                        />
                                                                    </Link>
                                                                </span>
                                                            }
                                                        >
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/lettings-details"
                                                                className={location.pathname === '/lettings-details' && 'active'}
                                                                // onClick={() => window.location.replace('/services2#letting')}
                                                            >
                                                                Lettings
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/renting-details"
                                                                className={location.pathname === '/renting-details' && 'active'}
                                                                // onClick={() => window.location.replace('/services2#renting')}
                                                            >
                                                                Renting
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/landlords-details"
                                                                className={location.pathname === '/landlords-details' && 'active'}
                                                                // onClick={() => window.location.replace('/services2#landlords')}
                                                            >
                                                                Landlords
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/tenants-details"
                                                                className={location.pathname === '/tenants-details' && 'active'}
                                                                // onClick={() => window.location.replace('/services2#tenants')}
                                                            >
                                                                Tenants
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/refurbishments-details"
                                                                className={location.pathname === '/refurbishments-details' && 'active'}
                                                                // onClick={() => {
                                                                //     window.location.replace('/services2#property-refurbishmen');
                                                                // }}
                                                            >
                                                                Property Refurbishments
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item
                                                                as={Link}
                                                                to="/management-details"
                                                                className={location.pathname === '/management-details' && 'active'}
                                                                // onClick={() => {
                                                                //     window.location.replace('/services2#property-management');
                                                                // }}
                                                            >
                                                                Property Management
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                        <span className="nav-border-custom"></span>
                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/gallery"
                                                                className={location.pathname === '/gallery' && 'active'}
                                                                onClick={() => window.location.replace('/gallery')}
                                                            >
                                                                Gallery
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>
                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/about"
                                                                className={location.pathname === '/about' && 'active'}
                                                            >
                                                                About us
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>

                                                        <div className="navbar-light navbar-nav nav-link" id="my-a">
                                                            <NavLink
                                                                as={Link}
                                                                to="/contact"
                                                                className={location.pathname === '/contact' && 'active'}
                                                            >
                                                                Contact Us
                                                            </NavLink>
                                                        </div>
                                                        <span className="nav-border-custom"></span>
                                                    </Nav>

                                                    <div className="offcanvas-footer nav-footer">
                                                        <div className="contact-info">
                                                            <h4>Contact Info</h4>
                                                            <ul>
                                                                <li>Pinetree Business Center, Durham Road. Birtley GATESHEAD. DH3 2TD</li>
                                                                <li>
                                                                    <a href="tel:07985 193841">07985 193841</a>
                                                                </li>
                                                                <li>
                                                                    <a href="mailto:manav@imperiallettings.co.uk">
                                                                        manav@imperiallettings.co.uk
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="social-links">
                                                            <ul className="clearfix">
                                                                {/* <li>
                                                                    <a href="/">
                                                                        <span className="fab fa-twitter"></span>
                                                                    </a>
                                                                </li> */}
                                                                <li>
                                                                    <a
                                                                        href="https://www.facebook.com/imperiallettingsnortheast"
                                                                        target="_blank"
                                                                    >
                                                                        <span className="fab fa-facebook-square"></span>
                                                                    </a>
                                                                </li>
                                                                {/* <li>
                                                                    <a href="/">
                                                                        <span className="fab fa-pinterest-p"></span>
                                                                    </a>
                                                                </li> */}
                                                                <li>
                                                                    <a
                                                                        href="https://www.instagram.com/imperial_lettings_northeast/"
                                                                        target="_blank"
                                                                    >
                                                                        <span className="fab fa-instagram"></span>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        href="https://www.linkedin.com/in/manav-seth-15844111/?originalSubdomain=uk"
                                                                        target="_blank"
                                                                    >
                                                                        <span className="fab fa-brands fa-linkedin"></span>
                                                                    </a>
                                                                </li>
                                                                {/* <li>
                                                                    <a href="/">
                                                                        <span className="fab fa-youtube"></span>
                                                                    </a>
                                                                </li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Body>
                                            </Navbar.Offcanvas>
                                        </Container>
                                    </Navbar>
                                ))}
                            </div>
                            <div className="btn-box">
                                <a href="/contact" className="theme-btn btn-one">
                                    Free Consultation
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {sticky && (
                    <div
                        style={{
                            height: `${stickyRef.current?.clientHeight}px`,
                        }}
                    />
                )}
            </header>

            <button
                className="scroll-top scroll-to-target"
                data-target="html"
                onClick={scrollToTop}
                style={{ display: visible ? 'inline' : 'none' }}
            >
                <span className="fal fa-angle-up" />
            </button>
        </div>
    );
};

export default Header;
