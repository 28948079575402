import React from 'react';

const Cta = () => {
    return (
        <>
            {/* cta-section */}
            <section
                className="cta-section alternate-2 pb-240 centred"
                style={{ backgroundImage: 'url(assets/images/background/cta-1.jpg)' }}
            >
                <div className="auto-container">
                    <div className="inner-box clearfix">
                        <div className="text">
                            <h2>Looking to Rent a new property</h2>
                        </div>
                        <div className="btn-box">
                            <a href="/" className="theme-btn btn-three">
                                Free Consultation
                            </a>
                            <a href="/" className="theme-btn btn-one">
                                Free Valuation
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* cta-section end */}
        </>
    );
};

export default Cta;
