import React from 'react';

const ContactTitle = () => {
    return (
        <>
            {/*Page Title*/}
            <section className="page-title centred" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }}>
                <div className="auto-container">
                    <div className="content-box clearfix">
                        <h1>Contact Us</h1>
                        <ul className="bread-crumb clearfix">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*End Page Title*/}
        </>
    );
};

export default ContactTitle;
