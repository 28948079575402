import React from 'react';
import './ContactInfo.css';

const ContactInfo = () => {
    return (
        <>
            {/* contact-info-section */}
            <section className="contact-info-section sec-pad centred bg-color-1">
                <div className="auto-container">
                    <div className="sec-title">
                        <h5>Contact us</h5>
                        <h2>Get In Touch</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 ">
                            <div className="info-block-one">
                                <div className="inner-box info-block">
                                    <div className="icon-box">
                                        <i className="icon-32" />
                                    </div>
                                    <h4>Email Address</h4>
                                    <p>
                                        <a href="mailto:manav@imperiallettings.co.uk">manav@imperiallettings.co.uk</a>
                                        <br />
                                        <a href="mailto:info@imperiallettings.co.uk">info@imperiallettings.co.uk</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="info-block-one">
                                <div className="inner-box info-block">
                                    <div className="icon-box two">
                                        <i className="icon-33" />
                                    </div>
                                    <h4>Phone Number</h4>
                                    <p>
                                        <a href="tel:07985 193841">07985 193841</a>
                                        <br />
                                        <a href="tel:07985 193841">07985 193841</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="info-block-one">
                                <div className="inner-box info-block">
                                    <div className="icon-box three">
                                        <i className="icon-34" />
                                    </div>
                                    <h4>Office Address</h4>
                                    <p>
                                        Pinetree Business Centre,
                                        <br /> Durham Road. Birtley
                                        <br /> GATESHEAD. DH3 2TD
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact-info-section end */}
        </>
    );
};

export default ContactInfo;
