const LattingsFaqData = [
    {
        id: 1,
        queation: 'What is a letting agent?',
        answer: 'A letting agent is a professional who helps landlords rent out their properties. They handle tasks such as advertising the property, conducting viewings, screening tenants, and drawing up tenancy agreements.',
    },
    {
        id: 2,
        queation: 'How do I find a good letting agent?',
        answer: 'You can find a good letting agent by asking for recommendations from friends or family, searching online for reviews, or checking with professional bodies like the National Association of Estate Agents (ARLA) for Letting Agents.',
    },
    {
        id: 3,
        queation: 'What fees can letting agents charge?',
        answer: 'Letting agents can charge fees for services like tenant referencing, inventory checks, and tenancy agreement drafting. These fees should be clearly outlined in the letting agents terms of business.',
    },
    {
        id: 4,
        queation: 'How long does a tenancy agreement last? ',
        answer: 'A tenancy agreement can last for any length of time agreed upon by the landlord and tenant. Most agreements are for six or twelve months, but they can be shorter or longer.',
    },
    {
        id: 5,
        queation: 'How much notice does a tenant need to give before leaving a property?',
        answer: 'The amount of notice a tenant needs to give before leaving a property depends on the terms of the tenancy agreement. Typically, it is one months notice, but it can be longer or shorter.',
    },
    {
        id: 6,
        queation: 'What is a security deposit?',
        answer: 'A security deposit is a sum of money paid by a tenant to a landlord before moving in. It acts as security against any damage or unpaid rent that may occur during the tenancy. The amount of the deposit is usually equivalent to one or two months rent. ',
    },
];
export default LattingsFaqData;
