import React from 'react';

const StyleOne = () => {
    return (
        <section className="gallery-style-one centred">
            <div className="auto-container">
                <div className="sec-title">
                    {/* <h5>Style 1</h5> */}
                    <h2>Property Wise Gallery</h2>
                </div>
                <div className="sortable-masonry">
                    <div className="filters">
                        <ul className="filter-tabs filter-btns clearfix">
                            <li className="active filter" data-role="button" data-filter=".all">
                                All
                            </li>
                            <li className="filter" data-role="button" data-filter=".real_estate">
                                Lettings
                            </li>
                            <li className="filter" data-role="button" data-filter=".architechture">
                                Rentings
                            </li>
                            <li className="filter" data-role="button" data-filter=".interior">
                                Tenants
                            </li>
                        </ul>
                    </div>
                    <div className="items-container row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all real_estate architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-16.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-16.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all real_estate interior">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-17.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-17.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all interior architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-18.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-18.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12 masonry-item small-column all real_estate architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-21.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-21.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all real_estate architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-29.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-29.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all real_estate interior">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-35.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-35.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all interior architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-25.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-25.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all real_estate architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-37.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-37.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all interior architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-32.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-32.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all interior architechture">
                            <div className="gallery-block-one">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src="assets/images/gallery/gallery-33.jpg" alt="" />
                                        </figure>
                                        <a href="assets/images/gallery/gallery-33.jpg" className="lightbox-image" data-fancybox="gallery">
                                            <i className="icon-31" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StyleOne;
