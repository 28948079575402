const TestimonialData = [
    {
        id: 1,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-1.jpg'],
        desc: 'Property Manager – Manav Seth of Imperial Lettings North East Limited is attentive and handle the issue quickly and effectively. Reliable professional and beneficial.',
        testimonialName: 'Thomas D',
        designation: '',
    },
    {
        id: 2,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-2.jpg'],
        desc: 'Unlike other organisations, Imperial Lettings North East Limited provided customised support, addressed quickly to any problems, and fulfilled what they promised to do when they originally said they would. Many thanks to MANAV SETH.',
        testimonialName: 'James',
        designation: '',
    },
    {
        id: 3,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-3.jpg'],
        desc: "Hi Manav,Just want to say thank you for being a good landlord and handling everything so well.I'm happy that you were really happy with the way I've left the apartment. I wish you all the best. Many thanks again.",
        testimonialName: 'Laura',
        designation: '',
    },
    {
        id: 4,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-1.jpg'],
        desc: "I admit that I wouldn't have looked for a more welcoming individual property manager. I never encountered an issue with the residence. Annual gas and boiler inspections are a need for a well-kept home. Checked in periodically to verify whether things are going well, and that means a lot to me because I believe Manav Seth considered me to be more than just a tenant but also a friend. Would most certainly suggest to anybody searching for property. ",
        testimonialName: 'James',
        designation: '',
    },
    {
        id: 5,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-2.jpg'],
        desc: 'Given my entire optimism regarding the high grade of service that Manav Seth would render, I have no problems about recommending someone to pick up a rental house from Imperial Lettings North East Limited. After two negative experiences with letting agencies in past, Manav has restored my faith in the private renting, giving me the peace of mind that my needs will be met without any personal inconvenience.',
        testimonialName: 'Nichola',
        designation: '',
    },
    {
        id: 6,
        iconClassName: 'icon-18',
        thumbimage: ['assets/images/resource/test-2.jpg'],
        desc: "Greetings, my name is Natalie Floyd, and I presently renting a two-bedroom flat off Manav Seth where I reside with my kid, who is six years old. I've been living this house for a period of over a year, and during that time I've discovered Manav Seth to a professional property manager. Manav has consistently been available for extra assistance when I have required it, and if I've had something go wrong with the rental, he has quickly fixed it. It is a delight to rent an apartment from Imperial Lettings North East Limited, and I would definitely recommend doing so in the years to come.",
        testimonialName: 'James H',
        designation: '',
    },
];

export default TestimonialData;
