import React, { Fragment } from 'react';
import './Testimonials.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import TestimonialData from '../.././Home/component/TestimonialData';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

const Tesimonials = () => {
    return (
        <>
            {/* testimonial-style-two */}
            <section
                className="testimonial-style-two"
                style={{
                    backgroundImage: 'url(assets/images/background/testimonial-1.jpg)',
                }}
            >
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-xl-6 col-lg-12 col-md-12 offset-xl-6 inner-column">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                pagination={true}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="services-one-testimonials"
                            >
                                {TestimonialData.map((i) => {
                                    return (
                                        <Fragment key={i?.id}>
                                            <SwiperSlide>
                                                <div className="testimonial-block-two">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <i className={i?.iconClassName} />
                                                        </div>
                                                        <div className="text">
                                                            <h3 key={i?.desc}>{i?.desc}</h3>
                                                        </div>
                                                        <div className="author-info">
                                                            <figure className="author-thumb">
                                                                <img src={i?.thumbimage} alt="" />
                                                            </figure>
                                                            <h4 key={i?.testimonialName}>{i?.testimonialName}</h4>
                                                            <span className="designation" key={i?.designation}>
                                                                {i?.designation}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Fragment>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial-style-two end */}
        </>
    );
};

export default Tesimonials;
