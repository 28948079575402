import React from 'react';

const AgentDetails = () => {
    return (
        <>
            <section className="agent-details">
                <div className="auto-container">
                    <div className="agent-details-content">
                        <div className="agents-block-one">
                            <div className="inner-box mr-0">
                                <figure className="image-box">
                                    <img src="assets/images/agents/about-4.jpg" alt="" />
                                </figure>
                                <div className="content-box">
                                    <div className="upper clearfix">
                                        <div className="title-inner pull-left">
                                            <h4>Manav Seth</h4>
                                            <span className="designation">A professional Letting agent based in Durham</span>
                                        </div>
                                        <ul className="social-list pull-right clearfix">
                                            <li>
                                                <a href="https://www.facebook.com/imperiallettingsnortheast" target="_blank">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.linkedin.com/in/manav-seth-15844111/?originalSubdomain=uk"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/imperial_lettings_northeast/" target="_blank">
                                                    <i className="fab fa-instagram" />
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="/">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Success isn’t really that difficult. There is a significant portion of the population here in
                                            North East, that actually want and need success to be hard! Why? So they then have a built-in
                                            excuse.when things don’t go their way! Pretty sad situation, to say the least. Have some fun and
                                            hypnotize yourself to be your very own Ghost of Christmas future”
                                        </p>
                                    </div>
                                    <ul className="info clearfix mr-0">
                                        <li>
                                            <i className="fab fa fa-envelope" />
                                            <a href="mailto:manav@imperiallettings.co.uk">manav@imperiallettings.co.uk</a>
                                        </li>
                                        <li>
                                            <i className="fab fa fa-phone" />
                                            <a href="tel:07985 193841">07985 193841</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AgentDetails;
