const DealData = [
    {
        id: 1,
        PropertyImages: [
            {
                id: 1,
                img: 'assets/images/property/property-1.jpg',
            },
            // {
            //     id: 2,
            //     img: 'assets/images/resource/deals-1.jpg',
            // },
        ],
        propertyTitle: 'Rent',
        propertyPrice: '£ Rental POA',
        // propertyAutherImg: "assets/images/feature/author-1.jpg",
        // propertyAutherName: "Michael Bean",
        propertyDesc:
            'We are proud to offer this delightful 2 bedroom, 1 bathroom ground floor apartment in a great location of DURHAM. Fully carpeted. Gas Central Heating. Double Glazing.',
        propertyMoreDetails: ['2 Beds', 'Mid Terrace'],
    },
    {
        id: 2,
        PropertyImages: [
            {
                id: 1,
                img: 'assets/images/property/property-2.jpg',
            },
            // {
            //     id: 2,
            //     img: 'assets/images/resource/deals-1.jpg',
            // },
        ],
        propertyTitle: 'Rent',
        propertyPrice: '£ Rental POA',
        propertyAutherImg: 'assets/images/feature/author-3.jpg',
        propertyAutherName: 'Keira Mel',
        propertyDesc: 'A 3 bed end terrace house going through full refurbishments - SR8 3QA.',
        propertyMoreDetails: ['3 Beds', 'Terrace'],
    },
    {
        id: 3,
        PropertyImages: [
            {
                id: 1,
                img: 'assets/images/property/property-3.jpg',
            },
            // {
            //     id: 2,
            //     img: 'assets/images/resource/deals-1.jpg',
            // },
        ],
        propertyTitle: 'Rent',
        propertyPrice: '£ Rental POA',
        propertyAutherImg: 'assets/images/feature/author-2.jpg',
        propertyAutherName: 'Robert Niro',
        propertyDesc: 'Available to rent. 2 bed mid terrace house with laminate flooring,double glazing, gas central heating.',
        propertyMoreDetails: ['2 Beds', 'Mid Terrace'],
    },
    {
        id: 4,
        PropertyImages: [
            {
                id: 1,
                img: 'assets/images/property/property-4.jpg',
            },
            // {
            //     id: 2,
            //     img: 'assets/images/resource/deals-1.jpg',
            // },
        ],
        propertyTitle: 'Rent',
        propertyPrice: '£ Rental POA',
        propertyAutherImg: 'assets/images/feature/author-2.jpg',
        propertyAutherName: 'Robert Niro',
        propertyDesc: '2 bed mid terrace house with double glazing, gas central heating. available very soon. Location: SR8  3LQ.',
        propertyMoreDetails: ['2 Beds', 'Mid Terrace'],
    },
];

export default DealData;
