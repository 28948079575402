import React from 'react';
import MessageRight from '../../MessageRight';
// import './SectionOne.css';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/tenant.jpg" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Tenants</h3>
                                            <div className="text">
                                                <p>
                                                    Property tenants are individuals or organizations that rent or lease a property from a
                                                    property owner or landlord. They pay a regular fee, known as rent, in exchange for the
                                                    right to occupy and use the property for a specified period of time, as outlined in a
                                                    lease agreement.
                                                </p>
                                                <p>
                                                    Property tenants can include individuals, families, or businesses, such as retail stores
                                                    or offices. They have certain rights and responsibilities, including the obligation to
                                                    pay rent on time, keep the property clean and well-maintained, and adhere to any rules
                                                    or regulations set forth by the landlord.
                                                </p>
                                                {/* <p className="m-0">
                                                    The process of property renting typically involves the following steps:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li>Property search</li>
                                                        <li>Property viewing</li>
                                                        <li>Rental application</li>
                                                        <li>Lease agreement</li>
                                                        <li>Move-in</li>
                                                        <li>Ongoing tenancy</li>
                                                    </ol>
                                                </blockquote> */}
                                                <p>
                                                    In return, tenants have the right to a safe and habitable living or working space, the
                                                    right to privacy, and the right to request repairs or maintenance when needed. The
                                                    specific rights and responsibilities of property tenants can vary depending on local
                                                    laws and the terms of the lease agreement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
