const RentingFaqData = [
    {
        id: 1,
        queation: 'What is a rental agreement?',
        answer: 'A rental agreement is a legal contract between a landlord and a tenant that outlines the terms and conditions of renting a property. It includes details such as the rent amount, payment due date, length of tenancy, and responsibilities of both parties. ',
    },
    {
        id: 2,
        queation: 'What is the difference between a lease and a rental agreement?',
        answer: 'A lease is a long-term rental agreement that typically lasts for a year or more, while a rental agreement is usually for a shorter period, such as a month-to-month agreement. A lease may include more detailed terms and conditions than a rental agreement. ',
    },
    {
        id: 3,
        queation: 'How much rent should I expect to pay? ',
        answer: 'The amount of rent you should expect to pay depends on various factors, such as the location, size, and condition of the property. You can research the rental prices of similar properties in the area to get an idea of what to expect. ',
    },
    {
        id: 4,
        queation: 'How often do I have to pay rent?  ',
        answer: 'The payment frequency of rent is usually outlined in the rental agreement or lease. It can be paid monthly, weekly, or bi-weekly, depending on what the landlord and tenant agree on. ',
    },
    {
        id: 5,
        queation: 'What is a security deposit, and how much should I expect to pay?',
        answer: 'A security deposit is a sum of money paid by the tenant to the landlord before moving in, which acts as security against any damage or unpaid rent that may occur during the tenancy. The amount of the deposit is typically equivalent to one- or two-months rent, but it can vary depending on the landlords policies. ',
    },
];
export default RentingFaqData;
