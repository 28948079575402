import React from 'react';
import Title from './component/Title';
import SectionOne from './component/SectionOne';
import Tesimonials from '../../../Pages/About/component/Testimonial';
import Cta from '../../../Pages/About/component/Cta';
import Funfact from '../../../Pages/About/component/Funfact';
import Contact from '../../../Pages/Home/component/Contact';
import LandlordsFAQ from './component/LandlordsFAQ';
const index = () => {
    return (
        <>
            <Title />
            <SectionOne />
            <Cta />
            <Funfact />
            <Tesimonials />
            <Contact />
            <LandlordsFAQ />
        </>
    );
};

export default index;
