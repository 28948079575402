import React from 'react';
import Error from './component/Error';
const index = () => {
    return (
        <>
            <Error />
        </>
    );
};

export default index;
