import React from 'react';

const Title = () => {
    return (
        <section className="page-title centred" style={{ backgroundImage: 'url(assets/images/background/page-title-4.jpg)' }}>
            <div className="auto-container">
                <div className="content-box clearfix">
                    <h1>Gallery</h1>
                    <ul className="bread-crumb clearfix">
                        <li>
                            <a href="index.html">Home</a>
                        </li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Title;
