const StyleTwoData = [
    {
        id: 1,
        image: 'assets/images/gallery/gallery-26.jpg',
    },
    {
        id: 2,
        image: 'assets/images/gallery/gallery-34.jpg',
    },
    {
        id: 3,

        image: 'assets/images/gallery/gallery-39.jpg',
    },
    {
        id: 4,
        image: 'assets/images/gallery/gallery-27.jpg',
    },
    // {
    //     id: 5,
    //     image: 'assets/images/gallery/gallery-26.jpg',
    // },
];

export default StyleTwoData;
