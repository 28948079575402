const LandloarsFaqData = [
    {
        id: 1,
        queation: 'What is a landlord? ',
        answer: 'A landlord is an individual or company who owns a property and rents it out to tenants.',
    },
    {
        id: 2,
        queation: 'What are the responsibilities of a landlord?',
        answer: "A landlord's responsibilities include maintaining the property in a habitable condition, providing necessary repairs, keeping the property safe and secure, and complying with all relevant laws and regulations. ",
    },
    {
        id: 3,
        queation: 'Can a landlord enter the property at any time?',
        answer: 'A landlord must give reasonable notice before entering the property. The notice period may vary depending on the jurisdiction, but it is usually 24 hours. ',
    },
    {
        id: 4,
        queation: 'How much notice does a landlord need to give before ending a tenancy? ',
        answer: "The notice period required by the landlord before ending a tenancy may vary depending on the jurisdiction and the terms of the rental agreement. In some places, a landlord must give at least 30 days' notice before ending a month-to-month tenancy. ",
    },
    {
        id: 5,
        queation: 'Can a landlord increase the rent during the tenancy?',
        answer: 'The landlord can only increase the rent during the tenancy if it is outlined in the rental agreement or lease. Some jurisdictions have laws that limit how much the landlord can increase the rent, so it is essential to check local regulations. ',
    },
    {
        id: 6,
        queation: 'What is a security deposit, and how much can a landlord charge?',
        answer: "A security deposit is a sum of money paid by the tenant to the landlord before moving in, which acts as security against any damage or unpaid rent that may occur during the tenancy. The amount of the deposit is typically equivalent to one or two months' rent, but it can vary depending on the landlord's policies. ",
    },
];
export default LandloarsFaqData;
