import React from 'react';
// import all Component

import AboutUs from './component/AboutUs';
// import Featured from './component/Featured';
import Testimonials from './component/Testimonials';
import Contact from './component/Contact';
import Cta from './component/Cta';
import ServicesFeature from '../Services1/component/ServicesFeature';
import Deal from './component/Deal';
import ChooseUs from './component/ChooseUs';
import BannerTwo from './component/BannerTwo';

const HomeIndex = () => {
    return (
        <>
            <BannerTwo />
            <AboutUs />
            <ServicesFeature />
            <Testimonials />
            <ChooseUs />
            {/* <Featured /> */}
            <Deal />
            <Contact />
            <Cta />
        </>
    );
};

export default HomeIndex;
