import React from 'react';
import AboutContentData from '../component/AboutContentData.js';

const About = () => {
    return (
        <section className="about-section bg-color-1">
            <div className="auto-container">
                <div className="row align-items-center clearfix">
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div className="image_block_2">
                            <div className="image-box">
                                <figure className="image">
                                    <img key={AboutContentData[1]?.serviceImg} src={AboutContentData[1]?.serviceImg} alt="" />
                                </figure>
                                <div className="text wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <h2>15+</h2>
                                    <h4>
                                        Years of <br />
                                        Experience
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div className="content_block_3">
                            <div className="content-box">
                                <div className="sec-title">
                                    <h5>About</h5>
                                    <h2>
                                        Imperial Lettings <br />
                                        (North East) Limited
                                    </h2>
                                </div>
                                <div className="text">
                                    <span>
                                        <p>
                                            <b>{AboutContentData[0]?.name}</b>
                                            {AboutContentData[0]?.longDesc}
                                        </p>
                                    </span>

                                    {/* <p>{AboutContentData[0]?.shortDesc}</p> */}
                                </div>
                                {/* <ul className="list clearfix">
                                    <li>{AboutContentData[0]?.serviceList[0]}</li>
                                    <li>{AboutContentData[0]?.serviceList[1]}</li>
                                    <li>{AboutContentData[0]?.serviceList[2]}</li>
                                    <li>{AboutContentData[0]?.serviceList[3]}</li>
                                    <li>{AboutContentData[0]?.serviceList[4]}</li>
                                    <li>{AboutContentData[0]?.serviceList[5]}</li>
                                </ul> */}
                                <div className="btn-box">
                                    <a href={AboutContentData[0]?.btnLink} className="theme-btn btn-one">
                                        {AboutContentData[0]?.btnName}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
