import React from 'react';
import MessageRight from '../../MessageRight';
// import './SectionOne.css';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/renting.jpg" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Renting</h3>
                                            <div className="text">
                                                <p>
                                                    Property renting refers to the act of renting a property from a landlord or property
                                                    owner for a specified period of time in exchange for regular rent payments. The rented
                                                    property can be a house, apartment, or other type of residential or commercial property.
                                                </p>
                                                <p className="m-0">
                                                    The process of property renting typically involves the following steps:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li>Property search</li>
                                                        <li>Property viewing</li>
                                                        <li>Rental application</li>
                                                        <li>Lease agreement</li>
                                                        <li>Move-in</li>
                                                        <li>Ongoing tenancy</li>
                                                    </ol>
                                                </blockquote>
                                                <p>
                                                    Overall, property renting can be a flexible and affordable housing option for
                                                    individuals and families. However, it is important to carefully review the lease
                                                    agreement and understand your rights and responsibilities as a tenant to ensure a
                                                    positive rental experience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
