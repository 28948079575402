import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// ** Third Party Components
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';
const Contact = () => {
    const form = useRef();

    const validationScheme = yup.object().shape({
        username: yup.string().required('Your Name is required.'),
        email: yup.string().email().required('Email is required.'),
        phone: yup.number().typeError('Please Enter Valid Number').required('Contact No is required.').nullable(),
        subject: yup.string().required('Subject  is required.'),
        message: yup.string().required('Message  is required.'),
    });

    // ** Hook Form
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme),
        // defaultValues: {
        //     username: '',
        //     email: '',
        //     phone: '',
        //     subject: '',
        //     message: '',
        // },
    });
    // register form fields
    const { ref: refUserName, ...restUserName } = register('username');
    const { ref: refEmail, ...restEmail } = register('email');
    const { ref: refPhone, ...restPhone } = register('phone');
    const { ref: refSubject, ...restSubject } = register('subject');
    const { ref: refMessage, ...restMessage } = register('message');

    const sendEmail = (e) => {
        // e.preventDefault();

        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then(
                (result) => {
                    toast.success('Messege Sent Successfully.');
                    reset();
                    // console.log(result.text);
                },
                (error) => {
                    toast.error(error.response?.data?.message || 'Something went wrong!');
                    // console.log(error.text);
                }
            );
    };

    return (
        <div className="">
            <section className="contact-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content-box">
                                <div className="sec-title">
                                    <h5>Contact</h5>
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="form-inner">
                                    <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    placeholder="Your Name"
                                                    required=""
                                                    invalid={errors?.username && true}
                                                    {...restUserName}
                                                    ref={refUserName}
                                                />
                                                {errors.username && <span className="text-danger">{errors.username?.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email address"
                                                    required=""
                                                    invalid={errors?.email && true}
                                                    {...restEmail}
                                                    ref={refEmail}
                                                />
                                                {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Phone"
                                                    required=""
                                                    maxLength="10"
                                                    invalid={errors?.phone && true}
                                                    {...restPhone}
                                                    ref={refPhone}
                                                />
                                                {errors.phone && <span className="text-danger">{errors.phone?.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    placeholder="Subject"
                                                    required=""
                                                    invalid={errors?.subject && true}
                                                    {...restSubject}
                                                    ref={refSubject}
                                                />
                                                {errors.subject && <span className="text-danger">{errors.subject?.message}</span>}
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <textarea
                                                    name="message"
                                                    placeholder="Message"
                                                    defaultValue={''}
                                                    invalid={errors?.message && true}
                                                    {...restMessage}
                                                    ref={refMessage}
                                                />
                                                {errors.message && <span className="text-danger">{errors.message?.message}</span>}
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                                <button className="theme-btn btn-one" type="submit" name="submit-form">
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image-box wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="3000ms">
                                <figure className="image">
                                    <img src="assets/images/resource/contact-1.jpg" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
