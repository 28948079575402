import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination, EffectFade } from 'swiper';
import './BannerTwo.css';

const BannerTwo = () => {
    return (
        <section className="banner-style-two centred">
            <Swiper
                speed={2000}
                // direction={'vertical'}
                // spaceBetween={30}
                observer={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                slidesPerView={1}
                pagination={true}
                loop={true}
                effect={'fade'}
                observeParents={true}
                fadeEffect={{ crossFade: true }}
                modules={[EffectFade, Autoplay, Pagination]}
                className="bannertwo-swiper"
            >
                <SwiperSlide>
                    <div className="slide-item-my">
                        <div className="image-layer" style={{ backgroundImage: 'url(assets/images/banner/banner-6.jpg)' }} />
                        <div className="auto-container">
                            <div className="content-box">
                                <h2>
                                    Welcome to IMPERIAL LETTINGS <br />
                                    (NORTH EAST) LIMITED
                                </h2>
                                <p>At Imperial we are providing a professional and efficient service is our top priority.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-item-my">
                        <div className="image-layer" style={{ backgroundImage: 'url(assets/images/banner/banner-5.jpg)' }} />
                        <div className="auto-container">
                            <div className="content-box">
                                <h2>We always believe in genuine deals with genuine clients</h2>
                                <p>A great home lifts the spirit and Calms the soul.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-item-my">
                        <div className="image-layer" style={{ backgroundImage: 'url(assets/images/banner/banner-7.jpg)' }} />
                        <div className="auto-container">
                            <div className="content-box">
                                <h2>Get in touch with us for all properties related updates</h2>
                                <p>Visit more in service section</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
};

export default BannerTwo;
