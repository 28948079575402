import React from 'react';
import ContactTitle from './component/ContactTitle';
import ContactInfo from './component/ContactInfo';
import Contact from './component/Contact';
import AgentDetails from './component/AgentDetails';

const ContectUsIndex = () => {
    return (
        <>
            <ContactTitle />
            <AgentDetails />
            <ContactInfo />
            <Contact />
        </>
    );
};

export default ContectUsIndex;
