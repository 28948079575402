import React from 'react';
import MessageRight from '../../MessageRight';
// import './SectionOne.css';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/refurbishments.jpg" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Refurbishments</h3>
                                            <div className="text">
                                                <p>
                                                    Property refurbishments refer to the process of renovating or upgrading an existing
                                                    property, typically with the goal of improving its condition or increasing its value.
                                                    Property refurbishments can range from minor cosmetic upgrades to major structural
                                                    changes, and can be carried out on residential or commercial properties.
                                                </p>
                                                <p className="m-0">
                                                    The process of property refurbishment typically involves the following steps:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li>Property assessment</li>
                                                        <li>Design and planning</li>
                                                        <li>Obtaining necessary permits</li>
                                                        <li>Implementation</li>
                                                        <li>Final inspection and completion</li>
                                                    </ol>
                                                </blockquote>
                                                <p>
                                                    Overall, property refurbishments can be a valuable investment for property owners
                                                    looking to improve the condition or value of their property. By working with experienced
                                                    professionals and carefully planning the project, property owners can achieve their
                                                    renovation goals while staying within budget and complying with all necessary
                                                    regulations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
