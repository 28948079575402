import React from 'react';
import Header from './component/Header';

const NavbarIndex = () => {
    return (
        <>
            <Header />
        </>
    );
};

export default NavbarIndex;
