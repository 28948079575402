import React from 'react';

const FaqRight = () => {
    return (
        <div className="faq-sidebar">
            <div className="question-inner">
                <div className="sec-title">
                    <h3>Its good to have questions</h3>
                </div>
                <div className="form-inner">
                    <figure className="image">
                        <img src="assets/images/resource/faq.jpg" alt="" />
                    </figure>
                </div>
            </div>
        </div>
    );
};

export default FaqRight;
