import React from 'react';
import './SectionOne.css';
import MessageRight from '../../MessageRight';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/letting.jpg" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Lettings </h3>
                                            <div className="text">
                                                <p>
                                                    Property lettings refer to the process of renting out a property to a tenant for a
                                                    specified period of time in exchange for rent payments. This can be done by individual
                                                    property owners or by property management companies who handle the rental process on
                                                    behalf of the property owner.
                                                </p>
                                                <p className="m-0">
                                                    The process of property lettings typically involves the following steps:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li> Advertising the property</li>
                                                        <li> Tenant screening</li>
                                                        <li> Lease agreement</li>
                                                        <li> Move-in</li>
                                                        <li> Ongoing management</li>
                                                    </ol>
                                                </blockquote>
                                                <p>
                                                    Overall, property lettings can be a profitable way for property owners to generate
                                                    income from their properties while providing housing options for tenants. However, it is
                                                    important to properly screen tenants and manage the property to ensure a successful
                                                    rental experience for both parties.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD
                                                     */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
