import React from 'react';
import Footer from './component/Footer';
import Partner from './component/Partner';
const FooterIndex = () => {
    return (
        <>
            <Partner />
            <Footer />
        </>
    );
};

export default FooterIndex;
