import React from 'react';
import Title from './component/Title';
import StyleOne from './component/StyleOne';
import Contact from '../ContectUs/component/Contact';
import StyleTwo from './component/StyleTwo';
// import StyleThree from './component/StyleThree';

const index = () => {
    return (
        <>
            <Title />
            <StyleOne />
            <StyleTwo />
            <Contact />

            {/* <StyleThree /> */}
        </>
    );
};

export default index;
