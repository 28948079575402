const RefurbishmentsFaqData = [
    {
        id: 1,
        queation: 'What is property refurbishment? ',
        answer: 'Property refurbishment is the process of renovating or improving a property to enhance its value, aesthetics, and functionality. ',
    },
    {
        id: 2,
        queation: 'What are the benefits of property refurbishment? ',
        answer: "The benefits of property refurbishment include increasing the property's value, improving its functionality, enhancing its aesthetics, and increasing its energy efficiency.",
    },
    {
        id: 3,
        queation: 'How much does property refurbishment cost? ',
        answer: 'The cost of property refurbishment varies depending on the scope of the project, the materials used, and the location of the property. It is essential to create a budget and obtain quotes from several contractors before embarking on a refurbishment project. ',
    },
    {
        id: 4,
        queation: 'What types of property refurbishment are there?',
        answer: 'There are several types of property refurbishment, including kitchen and bathroom refurbishments, structural renovations, exterior renovations, and energy efficiency upgrades. ',
    },
    {
        id: 5,
        queation: 'Do I need planning permission for property refurbishment?',
        answer: 'Planning permission may be required for some property refurbishment projects, depending on the scope and scale of the work. It is essential to check with the local planning department to determine if planning permission is required. ',
    },
    {
        id: 6,
        queation: 'What is a building permit, and do I need one for property refurbishment? ',
        answer: 'A building permit is a legal requirement that ensures the refurbishment project meets all building and safety codes. The need for a building permit depends on the scope and scale of the project, and it is essential to check with the local building department to determine if a building permit is required. ',
    },
];
export default RefurbishmentsFaqData;
