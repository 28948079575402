import React, { Fragment } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

import './Testimonials.css';
import TestimonialData from './TestimonialData';
const Testimonials = () => {
    return (
        <div>
            <section className="testimonial-section bg-color-1 centred">
                <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-1.png)' }} />
                <div className="auto-container">
                    <div className="sec-title">
                        <h5>Testimonials</h5>
                        <h2>What They Say About Us</h2>
                    </div>
                </div>

                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={false}
                    navigation={true}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="testimonials-custom-bg"
                >
                    {TestimonialData.map((i) => {
                        return (
                            <Fragment key={i?.id}>
                                <SwiperSlide>
                                    <div className="testimonial-block-one">
                                        <div className="inner-box">
                                            <figure className="thumb-box">
                                                <img src={i?.thumbimage} key={i?.thumbimage} alt="" />
                                            </figure>
                                            <div className="text">
                                                <p key={i.desc}>{i.desc}</p>
                                            </div>
                                            <div className="author-info">
                                                <h4 key={i?.testimonialName}>{i?.testimonialName}</h4>
                                                <span className="designation" key={i?.designation}>
                                                    {i?.designation}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Fragment>
                        );
                    })}
                </Swiper>
            </section>
        </div>
    );
};

export default Testimonials;
