import React from 'react';

// import animation
import CountUp from 'react-countup';
const Funfact = () => {
    return (
        <>
            {/* funfact-section */}
            <section className="funfact-section centred">
                <div className="auto-container">
                    <div className="inner-container wow slideInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                                <div className="funfact-block-one">
                                    <div className="inner-box">
                                        <div className="count-outer count-box">
                                            <CountUp end={1270} enableScrollSpy={true} />
                                        </div>
                                        <p>Total Professionals</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                                <div className="funfact-block-one">
                                    <div className="inner-box">
                                        <div className="count-outer count-box">
                                            <CountUp end={2350} enableScrollSpy={true} />
                                        </div>
                                        <p>Total Property Sell</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                                <div className="funfact-block-one">
                                    <div className="inner-box">
                                        <div className="count-outer count-box">
                                            <CountUp end={2540} enableScrollSpy={true} />
                                        </div>
                                        <p>Total Property Rent</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                                <div className="funfact-block-one">
                                    <div className="inner-box">
                                        <div className="count-outer count-box">
                                            <CountUp end={8270} enableScrollSpy={true} />
                                        </div>
                                        <p>Total Customers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* funfact-section end */}
        </>
    );
};

export default Funfact;
