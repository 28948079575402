const ManagementFaqData = [
    {
        id: 1,
        queation: 'What is property management? ',
        answer: 'Property management is the process of managing a property on behalf of the owner, including renting it out, maintaining it, and handling tenant concerns. ',
    },
    {
        id: 2,
        queation: 'What are the responsibilities of a property manager?',
        answer: "A property manager's responsibilities may vary depending on the terms of their agreement with the owner, but they typically include advertising and showing the property, screening tenants, collecting rent, handling repairs and maintenance, and resolving tenant complaints. ",
    },
    {
        id: 3,
        queation: 'What are the benefits of hiring a property manager?',
        answer: 'The benefits of hiring a property manager include professional management of the property, screening of tenants, collection of rent, handling of repairs and maintenance, and assistance with legal issues. ',
    },
    {
        id: 4,
        queation: 'How much does a property manager cost?',
        answer: "The cost of a property manager typically depends on the location of the property, the level of service required, and the manager's experience. Property managers typically charge a percentage of the monthly rent or a flat fee. ",
    },
    {
        id: 5,
        queation: 'What should I look for when hiring a property manager? ',
        answer: 'When hiring a property manager, it is essential to consider their experience, reputation, licensing, and insurance. It is also essential to understand their fee structure and the level of service they will provide. ',
    },
    {
        id: 6,
        queation: 'What is a property management agreement? ',
        answer: "A property management agreement is a legal agreement between the property owner and the property manager that outlines the terms of the manager's services, including fees, responsibilities, and expectations. ",
    },
];
export default ManagementFaqData;
