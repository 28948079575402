import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import './Footer.css';
const Partner = () => {
    return (
        <>
            <section className="testimonial-style-four partner sec-pad centred bg-color-1">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-12 col-sm-12 title-column">
                            <div className="sec-title">
                                {/* <h5>Our Pertners</h5> */}
                                <h2>We are going to Become Partners in Private rental market for the Long Run.</h2>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 inner-column">
                            {/* <div className="clients-logo"> */}
                            <Swiper
                                spaceBetween={10}
                                centeredSlides={false}
                                slidesPerGroup={2}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    468: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                }}
                                loop={true}
                                pagination={true}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="partner-custom"
                            >
                                <SwiperSlide>
                                    <figure className="logo">
                                        <a href="/">
                                            <img src="assets/images/clients/clients-6.png" alt="" />
                                        </a>
                                    </figure>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <figure className="logo">
                                        <a href="/">
                                            <img src="assets/images/clients/clients-7.png" alt="" />
                                        </a>
                                    </figure>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section>
        </>
    );
};

export default Partner;
