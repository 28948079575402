import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import './StyleTwo.css';

// import required modules
import { Autoplay, Pagination } from 'swiper';
import StyleTwoData from './StyleTwoData';
const StyleTwo = () => {
    return (
        <section className="gallery-style-two sec-pad centred bg-color-1">
            <div className="auto-container">
                <div className="sec-title">
                    {/* <h5>Style 2</h5> */}
                    <h2>Other Property Images</h2>
                </div>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={3}
                    spaceBetween={30}
                    // freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        461: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination]}
                    className="gallary-swiper"
                >
                    {StyleTwoData?.map((i) => {
                        return (
                            <Fragment key={i?.id}>
                                <SwiperSlide>
                                    <div className="gallery-block-two">
                                        <div className="inner-box">
                                            <figure className="image-box">
                                                <a href={i.image} className="lightbox-image" data-fancybox="gallery">
                                                    <img src={i?.image} alt="" />
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Fragment>
                        );
                    })}
                </Swiper>
            </div>
        </section>
    );
};

export default StyleTwo;
