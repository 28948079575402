import React, { Fragment, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import '../../FAQ.css';
import RefurbishmentsFaqData from './RefurbishmentsFAQData';
import FaqRight from '../../FaqRight';
const RefurbishmentsFAQ = () => {
    const [open, setOpen] = useState(1);
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <>
            <section className="faq-page-section sec-pad centred">
                <div className="auto-container">
                    <div className="sec-title">
                        <h5>Refurbishments FAQ’S</h5>
                        <h2>Frequently Asked Questions.</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-column">
                            <div className="faq-content-side">
                                <div>
                                    {RefurbishmentsFaqData.map((i) => {
                                        return (
                                            <Fragment key={i?.id}>
                                                <Accordion open={open} toggle={toggle}>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={i?.id}>{i?.queation}</AccordionHeader>
                                                        <AccordionBody accordionId={i?.id}>
                                                            <div className="acc-content current">
                                                                <div className="content-box">
                                                                    <p className="answer-p">{i?.answer}</p>
                                                                </div>
                                                            </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <FaqRight />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RefurbishmentsFAQ;
