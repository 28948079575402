import React from 'react';
import './Footer.css';
import logo from '../../../logowhite.svg';
const Footer = () => {
    const CurrentYearGet = new Date().getFullYear();
    return (
        <div className="boxed_wrapper">
            <footer className="main-footer">
                <div className="footer-top bg-color-2">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget about-widget">
                                    <div className="widget-title">
                                        <a href="/">
                                            <img src={logo} alt="logo" title="" width={250} height={250} />
                                        </a>
                                    </div>
                                    <div className="text">
                                        <p>
                                            At Imperial Lettings (North East) Limited we are providing a professional and efficient service
                                            is our top priority. Our team is dedicated to providing prompt, friendly and professional
                                            services at all times because they have been trained to the highest standards.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget links-widget ml-custom">
                                    <div className="widget-title">
                                        <h3>Explore</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="links-list class">
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/services1">Services</a>
                                            </li>
                                            <li>
                                                <a href="/gallery">Gallery</a>
                                            </li>
                                            <li>
                                                <a href="/about">About Us</a>
                                            </li>
                                            <li>
                                                <a href="/contact">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget links-widget ml-70">
                                    <div className="widget-title">
                                        <h3>Services</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="links-list class">
                                            <li>
                                                <a href="/lettings-details">Lettings</a>
                                            </li>
                                            <li>
                                                <a href="/renting-details">Renting</a>
                                            </li>
                                            <li>
                                                <a href="/tenants-details">Tenants</a>
                                            </li>
                                            <li>
                                                <a href="/landlords-details">Landlords</a>
                                            </li>
                                            <li>
                                                <a href="/refurbishments-details">Refurbishments</a>
                                            </li>
                                            <li>
                                                <a href="/management-details">Management</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget contact-widget">
                                    <div className="widget-title">
                                        <h3>Contacts</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="info-list clearfix">
                                            <li>
                                                <i className="fas fa-map-marker-alt" />
                                                {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                            </li>
                                            <li>
                                                <i className="fas fa-microphone" />
                                                <a href="tel:07985 193841">07985 193841</a>
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope" />
                                                <a href="mailto:manav@imperiallettings.co.uk">manav@imperiallettings.co.uk</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="inner-box clearfix">
                            <figure className="footer-logo">
                                <a href="/">
                                    <img src="assets/images/GroupWhite.png" alt="" width={90} height={80} />
                                </a>
                            </figure>
                            <div className="copyright">
                                <p>
                                    <a href="/">IMPERIAL LETTINGS (NORTH EAST) LIMITED</a> © {CurrentYearGet} All Right Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
