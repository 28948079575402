import React from 'react';
import Title from './component/Title';
import About from '../Home/component/AboutUs';
import Cta from './component/Cta';
import Funfact from './component/Funfact';
import TestimonialAbout from './component/TestimonialAbout';
import Contact from '../ContectUs/component/Contact';
import ServicesFeature from '../Services1/component/ServicesFeature';

const AboutIndex = () => {
    return (
        <>
            <Title />
            <About />
            <ServicesFeature />
            <Cta />
            <Funfact />
            <TestimonialAbout />
            <Contact />
        </>
    );
};

export default AboutIndex;
