import React from 'react';

const ChooseUs = () => {
    return (
        <>
            <section className="chooseus-section">
                <div className="auto-container">
                    <div className="inner-container bg-color-2">
                        <div className="upper-box clearfix">
                            <div className="sec-title light">
                                <h5>Why Choose Us?</h5>
                                <h2>Reasons To Choose Us</h2>
                            </div>
                            <div className="btn-box">
                                <a href="/contact" className="theme-btn btn-one">
                                    Free Valuation
                                </a>
                            </div>
                        </div>
                        <div className="lower-box">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                                    <div className="chooseus-block-one">
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <i className="icon-19" />
                                            </div>
                                            <h4>Excellent Reputation</h4>
                                            {/* <p>Lorem ipsum dolor sit consectetur sed eiusm tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                                    <div className="chooseus-block-one">
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <i className="icon-26" />
                                            </div>
                                            <h4>Best Local Agents</h4>
                                            {/* <p>Lorem ipsum dolor sit consectetur sed eiusm tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                                    <div className="chooseus-block-one">
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <i className="icon-21" />
                                            </div>
                                            <h4>Personalized Service</h4>
                                            {/* <p>Lorem ipsum dolor sit consectetur sed eiusm tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChooseUs;
