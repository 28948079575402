import React from 'react';

const Title = () => {
    return (
        <>
            <section className="page-title-two bg-color-1 centred">
                <div className="pattern-layer">
                    <div className="pattern-1" style={{ backgroundImage: 'url(assets/images/shape/shape-9.png)' }} />
                    <div className="pattern-2" style={{ backgroundImage: 'url(assets/images/shape/shape-10.png)' }} />
                </div>
                <div className="auto-container">
                    <div className="content-box clearfix">
                        <h1>Property Landlords </h1>
                        <ul className="bread-crumb clearfix">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Landlords Details</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Title;
