import React from 'react';
import MessageRight from '../../MessageRight';
// import './SectionOne.css';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/management.jpg" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Management</h3>
                                            <div className="text">
                                                <p>
                                                    Property management is the process of overseeing and managing real estate properties on
                                                    behalf of their owners. Property managers are responsible for maintaining and managing
                                                    all aspects of the property, including leasing, maintenance, and financial operations.
                                                    Property management can apply to a wide range of properties, including residential,
                                                    commercial, and industrial real estate.
                                                </p>
                                                <p className="m-0">
                                                    The role of a property manager typically involves the following responsibilities:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li>Tenant acquisition and management</li>
                                                        <li>Rent collection and financial management</li>
                                                        <li>Maintenance and repairs</li>
                                                        <li>Compliance with laws and regulations</li>
                                                        <li>Property inspections and evaluations</li>
                                                    </ol>
                                                </blockquote>
                                                <p>
                                                    Overall, property management is a complex and demanding role that requires a broad range
                                                    of skills and expertise. Property managers must be knowledgeable about real estate laws
                                                    and regulations, have strong financial and accounting skills, and possess strong
                                                    communication and interpersonal skills to effectively manage properties and work with
                                                    tenants, contractors, and other stakeholders.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
