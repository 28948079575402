import React from 'react';

const Cta = () => {
    return (
        <div className="boxed_wrapper">
            <section className="cta-section bg-color-2">
                <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-2.png)' }} />
                <div className="auto-container">
                    <div className="inner-box clearfix">
                        <div className="text pull-left">
                            <h2>Looking to Rent a new property</h2>
                        </div>
                        <div className="btn-box pull-right  mt-3">
                            <a href="/" className="theme-btn btn-three">
                                Free Consultation
                            </a>
                            <a href="/" className="theme-btn btn-one">
                                Free Valuation
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Cta;
