import React from 'react';
import MessageRight from '../../MessageRight';
// import './SectionOne.css';
const SectionOne = () => {
    return (
        <>
            <section className="property-details property-details-one">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src="assets/images/resource/landlord.png" alt="" />
                                            </figure>
                                            {/* <span className="category">Featured</span> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>Property Landlords</h3>
                                            <div className="text">
                                                <p>
                                                    Property landlords are individuals or businesses who own and rent out properties to
                                                    tenants for a specified period of time in exchange for regular rent payments. As a
                                                    landlord, you have the responsibility to manage your rental property and ensure that it
                                                    is in good condition for your tenants.
                                                </p>
                                                <p className="m-0">
                                                    As a property landlord, you have certain rights and responsibilities, including:
                                                </p>
                                                <blockquote>
                                                    <ol>
                                                        <li>Property maintenance</li>
                                                        <li>Complying with rental laws</li>
                                                        <li>Screening tenants</li>
                                                        <li>Lease agreements</li>
                                                        <li>Collecting rent</li>
                                                        <li>Security deposits</li>
                                                        <li>Respect for tenant rights</li>
                                                    </ol>
                                                </blockquote>
                                                <p>
                                                    Overall, being a property landlord can be a profitable and rewarding experience, but it
                                                    requires careful management and adherence to rental laws and regulations. By maintaining
                                                    your property, screening tenants, and communicating clearly with your tenants, you can
                                                    ensure a positive rental experience for both parties.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="property-sidebar default-sidebar">
                                <div className="author-widget sidebar-widget">
                                    <div className="author-box">
                                        <figure className="author-thumb">
                                            <img src="assets/images/resource/test-1.jpg" alt="" />
                                        </figure>
                                        <div className="inner">
                                            <h4>Manav Seth</h4>
                                            <ul className="info clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt" />
                                                    {/* Pinetree Business Centre, Durham Road. Birtley GATESHEAD. DH3 2TD */}
                                                    MILE HOUSE, BRIDGE END, CHESTER LE STREET. COUNTY DURHAM. DH3 3RA
                                                </li>
                                                <li>
                                                    <i className="fas fa-phone" />
                                                    <a href="tel:07985 193841">07985 193841</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <MessageRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionOne;
