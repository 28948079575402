import React, { Fragment } from 'react';
import ServiceData from './ServiceData';
import './ServicesFeature.css';
const ServicesFeature = () => {
    return (
        <>
            <section className="feature-style-three service-page centred">
                <div className="auto-container">
                    <div class="sec-title">
                        <h5>OUR SERVICES</h5>
                        <h2>Property Services</h2>
                    </div>
                    <div className="row clearfix">
                        {ServiceData.map((i) => {
                            return (
                                <Fragment key={i?.id}>
                                    <div
                                        className="col-lg-4 col-md-6 col-sm-12 feature-block"
                                        onClick={() => window.location.replace(i?.detailslink)}
                                    >
                                        <div
                                            className="feature-block-two wow fadeInUp animated"
                                            data-wow-delay="00ms"
                                            data-wow-duration="1500ms"
                                        >
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <i key={i?.iconClassName} className={i?.iconClassName} />
                                                </div>
                                                <h4 key={i?.title}>{i?.title}</h4>
                                                <p key={i?.desc}>{i?.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesFeature;
