const TenantsFaqData = [
    {
        id: 1,
        queation: 'What is a tenant?',
        answer: 'A tenant is someone who rents or leases a property from a landlord or property owner. ',
    },
    {
        id: 2,
        queation: 'What is a lease? ',
        answer: 'A lease is a legal agreement between the tenant and the landlord that outlines the terms of the tenancy, including the rent amount, lease length, security deposit, and rules and regulations. ',
    },
    {
        id: 3,
        queation: 'What is a security deposit? ',
        answer: 'A security deposit is a sum of money paid by the tenant to the landlord at the beginning of the lease term as a guarantee against any damages or unpaid rent. ',
    },
    {
        id: 4,
        queation: "What are the tenant's rights?",
        answer: "The tenant's rights may vary depending on the local laws and the terms of the lease agreement, but they generally include the right to a habitable dwelling, the right to privacy, and the right to reasonable notice before the landlord enters the property. ",
    },
    {
        id: 5,
        queation: "What are the tenant's responsibilities? ",
        answer: "The tenant's responsibilities typically include paying the rent on time, keeping the property in good condition, and following all rules and regulations outlined in the lease agreement.",
    },
    {
        id: 6,
        queation: 'How much notice is required to terminate a lease?',
        answer: "The amount of notice required to terminate a lease may vary depending on the local laws and the terms of the lease agreement. Typically, either party must provide at least 30 days' notice before terminating the lease. ",
    },
];
export default TenantsFaqData;
